/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Michelské pekárny Premium s.r.o. - Naše produkty"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="js-anim  --anim3 --center pt--80" style={{"backgroundColor":"rgba(207,102,102,1)","paddingBottom":15}} name={"uvod"} anim={"3"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--62" content={"<span style=\"color: var(--color-dominant);\">Naše produkty</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"marginBottom":0,"paddingBottom":7,"paddingTop":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"arq0hkrlgal"} css={css`
      background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--62" style={{"paddingTop":0,"marginTop":0,"marginBottom":0,"paddingLeft":0}} content={"<span style=\"color: var(--color-custom-1);\"><br>Křehké plátky, Křupky, Tyčinky<br><br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" style={{"paddingTop":4,"marginTop":3}} name={"kp"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--4 flex--top" style={{"marginTop":0,"paddingTop":0}} columns={"4"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":652}}>
              
              <Image style={{"maxWidth":276}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/f81fd00e5f8f47679463d70bc3467711_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38877/f81fd00e5f8f47679463d70bc3467711_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38877/f81fd00e5f8f47679463d70bc3467711_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38877/f81fd00e5f8f47679463d70bc3467711_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Křehké plátky kukuřičné s vlákninou, 65g"}>
              </Subtitle>

              <Text className="text-box" content={"č. výrobku 45120, hmotnost 65 g,\tbal.,&nbsp; 30 ks/karton,&nbsp; trv. 360 dnů"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image style={{"maxWidth":506}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/7c9d2651ebb44573a510ecaec139d3dd_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38877/7c9d2651ebb44573a510ecaec139d3dd_s=350x_.png 350w, https://cdn.swbpg.com/t/38877/7c9d2651ebb44573a510ecaec139d3dd_s=660x_.png 660w, https://cdn.swbpg.com/t/38877/7c9d2651ebb44573a510ecaec139d3dd_s=860x_.png 860w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Křehké plátky kukuřičné se sýrem, 65g"}>
              </Subtitle>

              <Text className="text-box" content={"č. výrobku 45150, hmotnost 65g, bal., 36 ks/karton, trv. 180 dnů"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":526}}>
              
              <Image style={{"maxWidth":358}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/f46b61bff6714eca916f6ddc01875cfc_s=350x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38877/f46b61bff6714eca916f6ddc01875cfc_s=350x_.jpg 350w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Křehké plátky kukuřičné Natural, 130g"}>
              </Subtitle>

              <Text className="text-box" content={"č. výrobku 45200, hmotnost 130 g, bal.,  12 ks/karton,  trv. 360 dnů"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5" style={{"maxWidth":486}}>
              
              <Image style={{"maxWidth":294}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/95e2044ddc0b46b3bb73c6a69df6654b_s=350x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38877/95e2044ddc0b46b3bb73c6a69df6654b_s=350x_.png 350w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Křehké plátky tmavé, 65g"}>
              </Subtitle>

              <Text className="text-box" content={"č. výrobku 45010, hmotnost 65g, bal., 36 ks/karton,&nbsp;trv. 360 dnů"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 mt--30" columns={"4"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":800}}>
              
              <Image style={{"maxWidth":296}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/a45f456f35164c9fa39cf28945213db6_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38877/a45f456f35164c9fa39cf28945213db6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38877/a45f456f35164c9fa39cf28945213db6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38877/a45f456f35164c9fa39cf28945213db6_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Křehké plátky sýrové, 65g"}>
              </Subtitle>

              <Text className="text-box" content={"č. výrobku 45210, hmotnost 65g, bal., 36 ks/karton, trv. 180 dnů"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image style={{"maxWidth":286,"paddingLeft":1}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/11c5e46a9b2c454a915aea5fae72753e_s=350x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38877/11c5e46a9b2c454a915aea5fae72753e_s=350x_.png 350w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Křehké plátky grahamové, 65g"}>
              </Subtitle>

              <Text className="text-box" content={"č. výrobku 45510, hmotnost 65g, bal., 36 ks/karton, trv. 360 dnů"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image style={{"maxWidth":280}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/ff55da0cd90e4832b0475beea2595ad7_s=350x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38877/ff55da0cd90e4832b0475beea2595ad7_s=350x_.png 350w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Křehké plátky rýžové, 65g"}>
              </Subtitle>

              <Text className="text-box" content={"č. výrobku 45710, hmotnost 65g, bal., 36 ks/karton, trv. 360 dnů"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image style={{"maxWidth":512}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/b7d2ce384e98430ca1d4fa366f2a0e73_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38877/b7d2ce384e98430ca1d4fa366f2a0e73_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38877/b7d2ce384e98430ca1d4fa366f2a0e73_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38877/b7d2ce384e98430ca1d4fa366f2a0e73_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"\tKřehké plátky Bruschetta, 65g"}>
              </Subtitle>

              <Text className="text-box" content={"č. výrobku 45320, hmotnost 65g, bal., 36 ks/karton, trv. 360 dnů"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image style={{"maxWidth":462}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/694208df40f242b6a2451635164d87f7_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38877/694208df40f242b6a2451635164d87f7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38877/694208df40f242b6a2451635164d87f7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38877/694208df40f242b6a2451635164d87f7_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"\tKřehké plátky kukuřičné chia, 65g"}>
              </Subtitle>

              <Text className="text-box" content={"č. výrobku 45330, hmotnost 65 g, bal.,  30 ks/karton,  trv. 360 dnů"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" style={{"paddingTop":4,"marginTop":3}} name={"krupky-tycky"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--4 mt--30" columns={"4"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image style={{"maxWidth":348}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/74b70606193c423f92051730d3831758_s=350x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38877/74b70606193c423f92051730d3831758_s=350x_.png 350w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"\tKřehké křupky kukuřičné, 90g"}>
              </Subtitle>

              <Text className="text-box" content={"č. výrobku 47010, hmotnost 90 g, bal., 10 ks/karton, trv. 360 dnů"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image style={{"maxWidth":334}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/10f2397778d14a1db978aa4da3aad41d_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38877/10f2397778d14a1db978aa4da3aad41d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38877/10f2397778d14a1db978aa4da3aad41d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38877/10f2397778d14a1db978aa4da3aad41d_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"\tKřehké kukuřičné tyčinky, 70g"}>
              </Subtitle>

              <Text className="text-box" content={"č. výrobku 46020, hmotnost 70g, bal., 23 ks/karton, trv. 360 dnů"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":0,"paddingBottom":2,"marginBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"il12q1r9adh"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"paddingTop":0,"paddingLeft":4}} columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"marginBottom":0,"paddingBottom":1,"paddingTop":0}}>
              
              <Title className="title-box title-box--center fs--62" content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\"><br>Metro dezert<br></span><br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" style={{"paddingTop":4,"marginTop":3}} name={"metro"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--4 flex--top" style={{"marginTop":0,"paddingTop":0}} columns={"4"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":652}}>
              
              <Image style={{"maxWidth":562}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/7fd1f3161d3a4078a5947b04c91514b6_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38877/7fd1f3161d3a4078a5947b04c91514b6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38877/7fd1f3161d3a4078a5947b04c91514b6_s=660x_.jpg 660w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Metro dezert Klasik, 120g"}>
              </Subtitle>

              <Text className="text-box" content={"č. výrobku 80080, hmotnost 120g,\tbal., 25 ks/karton,&nbsp; trv. 90 dnů<br>č. výrobku 80250, hmotnost 120g, bal.,  25 ks/karton,  trv. 90 dnů<br>č. výrobku 80270, hmotnost 120g, bal., 10 ks/karton,  trv. 90 dnů\n&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":526}}>
              
              <Image style={{"maxWidth":352}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/c01ba8baa671428ca179bba3d237b501_s=350x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38877/c01ba8baa671428ca179bba3d237b501_s=350x_.jpg 350w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"\tMetro dezert s příchutí skořice, 120g"}>
              </Subtitle>

              <Text className="text-box" content={"č. výrobku 80260, hmotnost 120g, bal., 25 ks/karton,  trv. 90 dnů<br>č. výrobku 80280, hmotnost 120g, bal., 10 ks/karton, trv. 90 dnů<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5" style={{"maxWidth":486}}>
              
              <Image style={{"maxWidth":510}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/a75fddffe02d499282bae0d2e7abb293_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38877/a75fddffe02d499282bae0d2e7abb293_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38877/a75fddffe02d499282bae0d2e7abb293_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38877/a75fddffe02d499282bae0d2e7abb293_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38877/a75fddffe02d499282bae0d2e7abb293_s=1400x_.jpg 1400w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"\tMetro dezert Vaječný likér, 120g"}>
              </Subtitle>

              <Text className="text-box" content={"č. výrobku 80150, hmotnost 120g, bal., 25 ks/karton,  trv. 90 dnů"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5" style={{"maxWidth":486}}>
              
              <Image style={{"maxWidth":510}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/e37bc7f26f8f4159aab1a60864d026bb_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38877/e37bc7f26f8f4159aab1a60864d026bb_s=350x_.png 350w, https://cdn.swbpg.com/t/38877/e37bc7f26f8f4159aab1a60864d026bb_s=660x_.png 660w, https://cdn.swbpg.com/t/38877/e37bc7f26f8f4159aab1a60864d026bb_s=860x_.png 860w, https://cdn.swbpg.com/t/38877/e37bc7f26f8f4159aab1a60864d026bb_s=1400x_.png 1400w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"\tMetro dezert Moccacino, 120g"}>
              </Subtitle>

              <Text className="text-box" content={"č. výrobku 80350, hmotnost 120g, bal., 25 ks/karton,  trv. 90 dnů"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5" style={{"maxWidth":486}}>
              
              <Image style={{"maxWidth":272}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/da175d18d42b4fd38774eb7d6567b899_s=350x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38877/da175d18d42b4fd38774eb7d6567b899_s=350x_.png 350w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"\tMetro dezert Banán, 120g"}>
              </Subtitle>

              <Text className="text-box" content={"č. výrobku 80450, hmotnost 120g, bal., 25 ks/karton,  trv. 90 dnů"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5" style={{"maxWidth":486}}>
              
              <Image style={{"maxWidth":278}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/661b9e702df34d78aebb6c28151fdfaa_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38877/661b9e702df34d78aebb6c28151fdfaa_s=350x_.png 350w, https://cdn.swbpg.com/t/38877/661b9e702df34d78aebb6c28151fdfaa_s=660x_.png 660w, https://cdn.swbpg.com/t/38877/661b9e702df34d78aebb6c28151fdfaa_s=860x_.png 860w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"\tMetro dezert Klasik Retro edice, 120g"}>
              </Subtitle>

              <Text className="text-box" content={"č. výrobku 80250, hmotnost 120g, bal., 25 ks/karton,  trv. 90 dnů"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5" style={{"maxWidth":486}}>
              
              <Image style={{"maxWidth":472}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/6cf729bec0be414db4f01f7f8dc46f2b_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38877/6cf729bec0be414db4f01f7f8dc46f2b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38877/6cf729bec0be414db4f01f7f8dc46f2b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38877/6cf729bec0be414db4f01f7f8dc46f2b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38877/6cf729bec0be414db4f01f7f8dc46f2b_s=1400x_.jpg 1400w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"\tMetro dezert Mint, 120g"}>
              </Subtitle>

              <Text className="text-box" content={"č. výrobku 80290, hmotnost 120g, bal., 25 ks/karton,  trv. 90 dnů"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5" style={{"maxWidth":486}}>
              
              <Image style={{"maxWidth":480}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38877/acdbec1628064e9691f2023c19d55f34_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/38877/acdbec1628064e9691f2023c19d55f34_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38877/acdbec1628064e9691f2023c19d55f34_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38877/acdbec1628064e9691f2023c19d55f34_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38877/acdbec1628064e9691f2023c19d55f34_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/38877/acdbec1628064e9691f2023c19d55f34_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"\tMetro dezert Klasik XXL, 180g"}>
              </Subtitle>

              <Text className="text-box" content={"č. výrobku 80110, hmotnost 180g, bal., 39 ks/karton,  trv. 90 dnů"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"anq2oufah4f"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box" content={"Katalog produktů&nbsp;"} use={"file"} href={"https://cdn.swbpg.com/d/38877/a-nabidkovy-list-od-1-5-2024.pdf"} document={"4850a524f1a847b6b7dbb41a68ef11ae"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"var(--color-supplementary)"}} name={"paticka"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" style={{"maxWidth":1420}} anim={"2"} animS={"2"} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box fs--10" style={{"maxWidth":366}} content={"Údaje o společnosti <br>Michelské pekárny a.s.\n<br>Michelské pekárny Premium s.r.o.\n<br><br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box fs--11" style={{"maxWidth":366}} content={"<span style=\"font-weight: bold;\">Michelské pekárny Premium s.r.o.\n</span><br>sídlo a kontaktní adresa společnosti:\n<br>U krčského nádraží 229/17, Krč, 140 00 Praha 4\n<br>zapsaná v obchodním rejstříku Městského soudu v Praze,\n<br>oddíl C, vložka 198709\n<br>IČ: 242 63 362 DIČ: CZ24263362\n<br>Výše základního kapitálu 200 000,- Kč"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--left fs--11" style={{"maxWidth":473}} content={"<span style=\"font-weight: bold;\">Michelské pekárny a.s.\n</span><br>sídlo a kontaktní adresa společnosti: <br>U krčského nádraží 229/17, Krč, 140 00 Praha 4<br>zapsaná v obchodním rejstříku Městského soudu v Praze, <br>oddíl B, vložka 648\n<br>IČ: 161 92 583 DIČ: CZ16192583\n<br>Výše základního kapitálu 3 445 498,- Kč"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}